@import '../../styles/variable.scss';

.CardDetailsLayout {
  background-color: #F5F5F5 !important;

  .topHeader {
    margin: 20px 30px;
  }

  .container {
    padding-left: 2rem;
    width: 100%;

    .greyFontColor {
      color: #616161;
    }

    .arrowIconAndTextWrapper {
      display: flex;
      align-items: center;
      gap: 7px;
      padding: 0;
      color: $primary-color;
      background-color: rgba(171, 50, 45, 0.2);
      padding: 12px;
      margin-bottom: 0;

      .BackArrowIcon {
        color: $primary-color;
        font-size: 16px;
      }
    }

    .arrowIconAndTextWrapper:focus,
    .arrowIconAndTextWrapper:hover {
      color: $primary-color;
      background-color: rgba(171, 50, 45, 0.1);
    }

    .cardTitle {
      font-size: 25px;
      margin-top: 14px;
    }

    .detailsTabPane {
      margin-bottom: 2rem;
    }
  }

  .tabStatusTitle {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .editCardTitleIcon {
    margin-left: 10px;
  }
}

@media (max-width:768px) {
  .CardDetailsLayout {
    .topHeader {
      margin: 14px 8px;
    }

    .container {
      padding-left: 8px;

      .cardTitle {
        font-size: 18px;
        margin: 2px 0 8px 0;
        white-space: nowrap;
        width: calc(100% - 16px);
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .arrowIconAndTextWrapper {
        margin-bottom: 10px;
      }

      .cardTitle {
        margin-bottom: 0;
      }
    }
  }
}
