.roundDot-assigned,
.roundDot-inProgress {
  background-color: #faad14;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.roundDot-inDone {
  background-color: #00C317;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.roundDot-cancelled {
  background-color: rgba(0, 0, 0, 0.88);
  width: 9px;
  height: 9px;
  border-radius: 50%;
}
